import axios from 'axios';
import { logout, refreshJwt } from '../Services/http/auth.service';
import {
    clearLocalStorageItemValue,
    getLocalStorageItemValue,
    setLocalStorageItemValue,
} from '../Services/local-storage.service';

axios.defaults.withCredentials = true;

let isInterceptorRegistered = false;
export const refreshJwtfunc = async () => {
    const response = await refreshJwt();
    return response.data;
};

const axiosInterceptor = (currentUser: any) => {
    if (isInterceptorRegistered) return;
    isInterceptorRegistered = true;

    axios.interceptors.request.use(
        (config) => {
            if (config && config.headers) {
                if (currentUser?.jwt_token) {
                    config.headers.Authorization = `Bearer ${currentUser.jwt_token}`;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
    axios.interceptors.response.use(
        async (response) => {
            if (response.headers['initiate-refresh-flag'] === 'true') {
                console.info('Initiate Refreshing JWT...');
                const refreshJwtResponseData = await refreshJwtfunc();
                currentUser.jwt_token = refreshJwtResponseData.user_data.jwt_token;
                setLocalStorageItemValue('currentUser', JSON.stringify(currentUser));
            }
            return response;
        },
        (error) => {
            if (error?.response?.status === 401 || error?.response?.data?.error_code === 401.1) {
                logout().then(() => {
                    clearLocalStorageItemValue('currentUser');
                    window.location.reload();
                });
            }

            return Promise.reject(error);
        },
    );
};

export default axiosInterceptor;
